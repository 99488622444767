<template>
    <div class="help mt-12">
      <ciam-card class="help-card">
        <div class="help-title">
          <b>
            Need help ?
          </b>
          
        </div>
        <div class="help-content">
          <div class="help-content-line">
            <div class="help-content-line-logo">
              <svg class="w-6 h-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M96 0C43 0 0 43 0 96L0 416c0 53 43 96 96 96l288 0 32 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l0-64c17.7 0 32-14.3 32-32l0-320c0-17.7-14.3-32-32-32L384 0 96 0zm0 384l256 0 0 64L96 448c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16l192 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-192 0c-8.8 0-16-7.2-16-16zm16 48l192 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-192 0c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg>
            </div>
            <div class="help-content-line-text">
              Reach <u><a href="https://documentation.cloud-iam.com" target="_blank">Cloud-IAM documentation</a></u> or <span @click="viewAPIReference()"><u><a>API</a></u></span> and learn how to configure your Keycloak deployment
            </div>
          </div>
          <div class="help-content-line">
            <div class="help-content-line-logo">
              <svg class="w-6 h-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M88.2 309.1c9.8-18.3 6.8-40.8-7.5-55.8C59.4 230.9 48 204 48 176c0-63.5 63.8-128 160-128s160 64.5 160 128s-63.8 128-160 128c-13.1 0-25.8-1.3-37.8-3.6c-10.4-2-21.2-.6-30.7 4.2c-4.1 2.1-8.3 4.1-12.6 6c-16 7.2-32.9 13.5-49.9 18c2.8-4.6 5.4-9.1 7.9-13.6c1.1-1.9 2.2-3.9 3.2-5.9zM208 352c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176c0 41.8 17.2 80.1 45.9 110.3c-.9 1.7-1.9 3.5-2.8 5.1c-10.3 18.4-22.3 36.5-36.6 52.1c-6.6 7-8.3 17.2-4.6 25.9C5.8 378.3 14.4 384 24 384c43 0 86.5-13.3 122.7-29.7c4.8-2.2 9.6-4.5 14.2-6.8c15.1 3 30.9 4.5 47.1 4.5zM432 480c16.2 0 31.9-1.6 47.1-4.5c4.6 2.3 9.4 4.6 14.2 6.8C529.5 498.7 573 512 616 512c9.6 0 18.2-5.7 22-14.5c3.8-8.8 2-19-4.6-25.9c-14.2-15.6-26.2-33.7-36.6-52.1c-.9-1.7-1.9-3.4-2.8-5.1C622.8 384.1 640 345.8 640 304c0-94.4-87.9-171.5-198.2-175.8c4.1 15.2 6.2 31.2 6.2 47.8l0 .6c87.2 6.7 144 67.5 144 127.4c0 28-11.4 54.9-32.7 77.2c-14.3 15-17.3 37.6-7.5 55.8c1.1 2 2.2 4 3.2 5.9c2.5 4.5 5.2 9 7.9 13.6c-17-4.5-33.9-10.7-49.9-18c-4.3-1.9-8.5-3.9-12.6-6c-9.5-4.8-20.3-6.2-30.7-4.2c-12.1 2.4-24.8 3.6-37.8 3.6c-61.7 0-110-26.5-136.8-62.3c-16 5.4-32.8 9.4-50 11.8C279 439.8 350 480 432 480z"/></svg>            
            </div>    
              <div class="help-content-line-text">
                  You have any questions ? Contact our <span @click="viewTicketCenter()"><u><a>support team</a></u></span>
                </div>
            </div>
          </div>
      </ciam-card>
    </div>
</template>
<script>
import { routeNames } from '@/router';

export default {
    methods: {
        viewAPIReference() {
        return this.$router
            .push({
            name: routeNames.DocumentationAPI,
            })
            .catch(() => {});
        },
        viewTicketCenter() {
        return this.$router
            .push({
            name: routeNames.TicketList,
            })
            .catch(() => {});
        },
    },
}
</script>
<style>
.help {
  display: flex;
    justify-content: center;
    align-items: center;
}
.help-card{
  width: 60%;
}
.help-title {
  background-color: grey;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  color: white;
}
.help-content {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}
.help-content-line {
  display: flex;
  flex-direction: row;
  justify-content: center;
    align-items: center;
}
</style>